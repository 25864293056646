import { createContext, FC, ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { useCollection } from '@tatsuokaniwa/swr-firestore'
import { TeamLibraryTalentData } from '../types'
import { useTeamContext } from './TeamContext'
import { updateBrandTalent, updateTeamTalent } from '../api/firestore/talent'

interface ITalentContext {
    talent: TeamLibraryTalentData[]
    talentTagSet: string[]
    searchValue: string
    handleSetSearchValue: (searchString) => void
    handleUpdateTalent: (updatedFields, talentId, brand?) => void
    filters: string[]
    addFilter: (filter: string) => void
    removeFilter: (filter: string) => void
    setStatusFilter: (status: string) => void
    tagFilter: string[]
    handleSetTagFilter: (tags: string[]) => void
    showBrandTalent: string
    handleSetShowBrandTalent: (brandId: string) => void
    resetContext: () => void
}

export const TalentContext = createContext<ITalentContext>(null!)

export const useTalentContext = () => useContext(TalentContext)

const defaultFilters = ['status: [pending, approved, waitlist]']

const TalentContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const { currentTeam } = useTeamContext()

    const { data: talent = [] } = useCollection<TeamLibraryTalentData>(
        currentTeam?.id
            ? {
                  path: `teams/${currentTeam.id}/talentLibrary`,
                  parseDates: ['appliedAt', 'profile.createdAt'],
              }
            : null
    )

    const [filters, setFilters] = useState(defaultFilters)

    const [tagFilter, setTagFilter] = useState([])
    const [showBrandTalent, setShowBrandTalent] = useState('')

    const handleSetTagFilter = useCallback((tagArray: string[]) => {
        setTagFilter(tagArray)
        if (tagArray.length > 0) {
            setFilters((prev) => [...prev.filter((f) => !f.startsWith('tags:')), `tags: [${tagArray.join(', ')}]`])
        } else {
            setFilters((prev) => prev.filter((f) => !f.startsWith('tags:')))
        }
    }, [])

    const handleSetStatusFilter = useCallback(
        (statusFilter: string) => {
            const filters_ = filters.filter((f) => !f.startsWith('status:'))
            if (statusFilter) {
                setFilters([...filters_, statusFilter])
            }
        },
        [filters]
    )

    const handleUpdateTalent = useCallback(
        async (updatedFields, talentId, brand = '') => {
            if (brand) {
                try {
                    await updateBrandTalent(brand, talentId, updatedFields)
                } catch (err) {
                    console.log(err)
                }
            } else {
                try {
                    await updateTeamTalent(updatedFields, talentId, currentTeam.id)
                } catch (err) {
                    console.log(err)
                }
            }
        },
        [currentTeam]
    )

    const tagSet = useMemo(() => {
        return talent?.length > 0 ? Array.from(new Set(talent.flatMap((c) => c.tags))) : []
    }, [talent])

    const [searchValue, setSearchValue] = useState('')

    const removeFilter = useCallback((filter: string) => {
        setFilters((prev) => prev.filter((f) => !f.startsWith(filter)))
    }, [])

    const handleSetShowBrandTalent = useCallback((brandId: string) => {
        setShowBrandTalent(brandId)
    }, [])

    const resetContext = useCallback(() => {
        setSearchValue('')
        setFilters(defaultFilters)
        setTagFilter([])
        setShowBrandTalent('')
    }, [])

    const value = {
        talent,
        talentTagSet: tagSet,
        searchValue,
        handleSetSearchValue: (searchString) => setSearchValue(searchString),
        handleUpdateTalent,
        filters: Array.from(filters),
        addFilter: (filter: string) => setFilters((prev) => [...prev, filter]),
        removeFilter,
        setStatusFilter: handleSetStatusFilter,
        tagFilter,
        handleSetTagFilter,
        showBrandTalent,
        handleSetShowBrandTalent,
        resetContext,
    }

    return <TalentContext.Provider value={value}>{children}</TalentContext.Provider>
}

export default TalentContextProvider
