import { getApp, getApps, initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { getDatabase } from 'firebase/database'
import { getStorage } from 'firebase/storage'

const config = {
    app: {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_ID,
        appId: process.env.REACT_APP_APPID,
        measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    },
    alpha: {
        apiKey: process.env.REACT_APP_PROD_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROD_PROJECT_ID,
        storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_ID,
        appId: process.env.REACT_APP_ALPHA_APPID,
        measurementId: process.env.REACT_APP_ALPHA_MEASUREMENT_ID,
    },
    beta: {
        apiKey: process.env.REACT_APP_PROD_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROD_PROJECT_ID,
        storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_ID,
        appId: process.env.REACT_APP_PROD_APPID,
        measurementId: process.env.REACT_APP_PROD_MEASUREMENT_ID,
    },
    dev: {
        apiKey: process.env.REACT_APP_DEV_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_DEV_PROJECT_ID,
        storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_ID,
        appId: process.env.REACT_APP_DEV_APPID,
        measurementId: process.env.REACT_APP_DEV_MEASUREMENT_ID,
    },
}

const makeConfig = () => {
    const branch = process.env.GIT_BRANCH
    const channel = process.env.REACT_APP_FIREBASE_DEPLOY_CHANNEL

    const channelConfigMap = {
        alpha: config.alpha,
        alpha_preview: config.alpha,
        dev: config.dev,
        dev_preview: config.dev,
        beta: config.beta,
    }

    const branchConfigMap = {
        main: config.app,
        alpha: config.alpha,
        beta: config.beta,
    }

    // Return the config based on the channel if it exists in the map.
    if (channelConfigMap[channel]) {
        return channelConfigMap[channel]
    }

    // If no channel config is found, return the config based on the branch,
    return branchConfigMap[branch] || config.dev
}

const app = getApps().length === 0 ? initializeApp(makeConfig()) : getApp()

export const db = getFirestore(app)
export const auth = getAuth(app)
export const functions = getFunctions(app)
export const rtdb = getDatabase(app)
export const storage = getStorage(app)
// connectFunctionsEmulator(functions, 'localhost', 5001)