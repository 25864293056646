import { collection, doc, getDoc, setDoc } from 'firebase/firestore'
import { db } from '../../../firebase_config/firebase_config'

export interface BrandTags {
    clips: string[]
    elements: string[]
    deliverables: string[]
    shootingSessions: string[]
}

export const setBrandTagsFirestore = async (brandId: string, brandTags: BrandTags) => {
    const ref = doc(db, 'brandTags', brandId)
    try {
        await setDoc(ref, { brandTags })
    } catch (e) {
        console.log(e)
    }
}

export const getBrandTags = async (brandId: string) => {
    try {
        const ref = doc(db, 'brandTags', brandId)
        const docSnap = await getDoc(ref)
        if (docSnap.exists()) {
            return docSnap.data().brandTags
        } else {
            return null
        }
    } catch (e) {
        console.log(e)
    }
}
