import { collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where, writeBatch } from 'firebase/firestore'
import { db } from '../../../firebase_config/firebase_config'
import { Team, UserTeamEntry } from '../../../types'
import { chunk, omit } from 'lodash'

export const getTeamsByIds = async (teamEntries: Partial<UserTeamEntry>[]): Promise<Team[]> => {
    try {
        const promises = chunk(teamEntries, 30).map(async (teamEntries) => {
            const q = query(
                collection(db, `teams`),
                where(
                    '__name__',
                    'in',
                    teamEntries.map((t) => t.id)
                )
            )

            const res = await getDocs(q)
            if (res.empty) {
                return []
            }

            return res.docs?.map((d) => ({ ...(d.data() as Team), id: d.id }))
        })

        const res = await Promise.all(promises)
        return res.flat()
    } catch (error) {
        console.log(error)
    }
}

export const getTeam = async (teamId: string): Promise<Team> => {
    const teamRef = doc(db, 'teams', teamId)

    try {
        const snapshot = await getDoc(teamRef)
        return { id: snapshot.id, ...(snapshot.data() as Team) }
    } catch (error) {
        console.log(error.message)
    }
}

export const checkTeamExists = async (teamEmail: string): Promise<boolean> => {
    try {
        const res = await getDocs(query(collection(db, 'teams'), where('email', '==', teamEmail)))
        return res.docs.length > 0
    } catch (error) {
        console.log(error)
    }
}

export const revokeTeamAccess = async (teamId, brandId) => {
    const teamRef = doc(db, 'teams', teamId)
    const brandRef = doc(db, 'brands', brandId)

    try {
        const team = (await getDoc(teamRef)).data()
        const brand = (await getDoc(brandRef)).data()

        const brands = team.brands.filter((b) => b.id !== brandId)
        const teams = brand.teams.filter((t) => t.id !== teamId)

        const teamUserIds = team.users.map((u) => u.id)
        const assignedUsers = brand.assignedUsers.filter((u) => !teamUserIds.includes(u.id))
        const accessRequests = brand.accessRequests.filter((u) => !teamUserIds.includes(u.id))

        await updateDoc(teamRef, { brands })
        await updateDoc(brandRef, { teams, assignedUsers, accessRequests })
    } catch (error) {
        console.log(error)
    }
}

export const saveTeam = async (team) => {
    const teamRef = doc(db, 'teams', team.id)

    try {
        await updateDoc(teamRef, team)
    } catch (error) {
        console.log(error)
    }
}

export const getTeamByAuditionId = async (auditionId) => {
    try {
        const res = await getDocs(query(collection(db, 'teams'), where('auditionFormSettings.uuid', '==', auditionId)))
        return (res.docs?.[0].data() as Team) || null
    } catch (error) {
        throw new Error(error)
    }
}

export const updateName = async (teamId, name) => {
    const teamRef = doc(db, 'teams', teamId)

    try {
        await updateDoc(teamRef, { name })
    } catch (error) {
        console.log(error)
    }
}

export const updateLogo = async (teamId, logoURL) => {
    const teamRef = doc(db, 'teams', teamId)

    try {
        await updateDoc(teamRef, { logoURL })
    } catch (error) {
        console.log(error)
    }
}

const acceptAction = (index, list) => {
    const updatedList = [...list]
    updatedList.splice(index, 1, omit(updatedList[index], 'isPending'))
    return updatedList
}

const dismissAction = (index, list) => {
    const updatedList = [...list]
    updatedList.splice(index, 1)
    return updatedList
}

const inviteActions = async (teamId, brandId, notificationId, callback) => {
    const msgRef = doc(db, 'teams', teamId, 'notifications', notificationId)
    if (!(await getDoc(msgRef)).exists()) {
        return
    }

    const teamRef = doc(db, 'teams', teamId)
    const team = (await getDoc(teamRef)).data()

    const brandIndex = team.brands.findIndex((el) => el.id === brandId)
    await updateDoc(teamRef, { brands: callback(brandIndex, team.brands) })

    const brandRef = doc(db, 'brands', brandId)
    const brand = (await getDoc(brandRef)).data()

    const teamIndex = brand.teams.findIndex((el) => el.id === teamId)
    await updateDoc(brandRef, { teams: callback(teamIndex, brand.teams) })

    await deleteDoc(msgRef)
}

export const acceptInviteToBrand = async (params: { teamId: string; brandId: string; notificationId: string }) => {
    const { teamId, notificationId, brandId } = params
    return inviteActions(teamId, brandId, notificationId, acceptAction)
}

export const dismissInviteToBrand = async (params: { teamId: string; brandId: string; notificationId: string }) => {
    const { teamId, notificationId, brandId } = params
    return inviteActions(teamId, brandId, notificationId, dismissAction)
}

export const transferOwnership = async (team: Team, oldOwnerId, newOwnerId): Promise<void> => {
    const teamRef = doc(db, 'teams', team.id)
    const oldOwnerRef = doc(db, 'users', oldOwnerId)
    const newOwnerRef = doc(db, 'users', newOwnerId)

    const oldOwner = (await getDoc(oldOwnerRef)).data()
    const newOwner = (await getDoc(newOwnerRef)).data()

    const batch = writeBatch(db)

    batch.update(teamRef, {
        users: team.users.map((u) => {
            if (u.id === oldOwnerId) {
                return { ...u, teamRole: 'member' }
            }

            if (u.id === newOwnerId) {
                return { ...u, teamRole: 'owner' }
            }

            return u
        }),
    })

    batch.update(oldOwnerRef, {
        teams: oldOwner.teams.map((t) => {
            if (t.id === team.id) {
                return { ...t, teamRole: 'member' }
            }
        }),
    })

    batch.update(newOwnerRef, {
        teams: newOwner.teams.map((t) => {
            if (t.id === team.id) {
                return { ...t, teamRole: 'owner' }
            }
        }),
    })

    await batch.commit()
}
