import { useState, useEffect, useMemo } from 'react'
import { getProductsFirestore, removeProductFirestore, saveProductFirestore } from '../api/firestore/products'
import { ProductData } from '../types'
import { useBrandContext } from '../contexts/BrandContext'
import { useCollection, useGetDocs } from '@tatsuokaniwa/swr-firestore'
import { collection, getDocs, query, where } from '@firebase/firestore'
import { set } from 'lodash'
import { useEffectDebugger } from 'use-debugger-hooks'
import { PLAN } from '../features/Auth/contexts/BillingContext'

const useSubscriptionProducts = () => {
    const { data: products } = useGetDocs<any>({ path: `stripe-products` })
    const [subscriptionProducts, setSubscriptionProducts] = useState<any[]>(null)
    const [basePrices, setBasePrices] = useState(null)
    const [prices, setPrices] = useState(null)
    const [priceIds, setPriceIds] = useState(null)

    useEffect(() => {
        if (products) {
            const subs = products.filter((p) => p?.name?.includes(' OS'))

            const order = [PLAN.BASIC, PLAN.FULL, PLAN.PRO]

            const ordered = []
            order.forEach((nick) => {
                const sub = subs.find((s) => s.metadata.nickname === nick)
                if (sub) {
                    ordered.push(sub)
                }
            })

            setSubscriptionProducts(ordered)
        }
    }, [products])

    useEffect(() => {
        const getBasePrices = async () => {
            let prices = {}
            let priceIds = {}
            let basePrices = {}

            const promises = subscriptionProducts.map(async (p) => {
                const nickname = p.metadata.nickname

                const basePriceDesc = `${nickname}_5`

                const q = query(collection(p.ref, 'prices'))
                const querySnapshot = await getDocs(q)

                querySnapshot.forEach((doc) => {
                    const data = doc.data()

                    if (data.description === basePriceDesc) {
                        basePrices[nickname] = Number(data.unit_amount) / 100
                    }

                    if (data.description.includes(nickname)) {
                        set(prices, `${nickname}.${data.description}`, Number(data.unit_amount) / 100)
                        set(priceIds, `${nickname}.${data.description}`, doc.id)
                    }
                })
            })

            await Promise.all(promises)

            setBasePrices(basePrices)
            setPrices(prices)
            setPriceIds(priceIds)
        }

        if (subscriptionProducts && subscriptionProducts.length === 3) {
            getBasePrices()
        }
    }, [subscriptionProducts])

    const subscriptionProductsRes = useMemo(() => {
        return subscriptionProducts && basePrices
            ? subscriptionProducts.map((p) => {
                  const nickname = p?.metadata?.nickname

                  return {
                      ...p,
                      basePrice: basePrices[nickname],
                      prices: prices[nickname],
                      priceIds: priceIds[nickname],
                  }
              })
            : []
    }, [subscriptionProducts, basePrices, priceIds, prices])

    return {
        subscriptionProducts: subscriptionProductsRes,
    }
}

export default useSubscriptionProducts
