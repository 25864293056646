import { db, functions } from '../../../firebase_config/firebase_config'
import { setDoc, doc, updateDoc, getDoc, query, collection, where, getDocs, arrayUnion } from 'firebase/firestore'
import { User } from 'firebase/auth'
import { UserData } from '../../../types'
import { getContentById } from '../utils'
import { httpsCallable } from 'firebase/functions'

export const addUser = async (user: UserData) => {
    try {
        await setDoc(doc(db, 'users', user.uid), user)
        return user.uid
    } catch (error: any) {
        throw new Error(error.message, error.stack)
    }
}

export const updateUser = async (uid: string, newUser) => {
    const userRef = doc(db, 'users', uid)
    try {
        await updateDoc(userRef, newUser)
    } catch (error) {
        throw new Error(error.message)
    }
}

export const deleteUser = async () => {}

export const getUser = async (uid, queryFilter?: any) => {
    try {
        if (uid) {
            const ref = doc(collection(db, `users`), uid)

            const user = await getDoc(ref)

            if (!user?.exists()) {
                return null
            }

            return { ...user.data(), id: user.id } as UserData
        } else {
            const q = query(collection(db, `users`), queryFilter)

            const usersQuery = await getDocs(q)

            return { ...usersQuery.docs[0].data(), id: usersQuery.docs[0].id } as UserData
        }
    } catch (error) {
        console.log(error)
    }
}

export const getViscapUsers = async () => {
    const q = query(collection(db, 'users'), where('company', '==', 'Viscap Media'))
    let arr = []
    try {
        const res = await getDocs(q)
        res.docs.forEach((doc) => arr.push({ ...doc.data(), id: doc.id }))
        return arr
    } catch (error) {
        throw new Error(error)
    }
}

export const getUserByEmail = async (email: string): Promise<UserData> => {
    const q = query(collection(db, 'users'), where('email', '==', email))
    let arr: UserData[] = []
    try {
        const res = await getDocs(q)
        if (res.docs.length === 0) {
            return null
        }

        return { ...res.docs[0].data(), id: res.docs[0].id } as UserData
    } catch (error) {
        throw new Error(error)
    }
}

export const userWithEmailExists = async (email: string) => {
    const q = query(collection(db, 'users'), where('email', '==', email))
    const snapshot = await getDocs(q)
    const count = snapshot.size
    return count > 0
}

export const getUsersByTeam = async (teamId: string): Promise<UserData[]> => {
    const teamRef = doc(db, 'teams', teamId)

    try {
        const teamDoc = await getDoc(teamRef)
        const teamData = teamDoc.data()

        const promises = teamData?.users?.map(async (member) => {
            return getUser(member.id)
        })

        const res = await Promise.all(promises)

        return res.filter((u) => u !== null)
    } catch (error) {
        throw new Error(error)
    }
}

export const getUsernamesByIds = async (ids: string[]) => {
    try {
        const res = await getContentById(ids, 'users')
        return res.map((u) => ({ id: u.uid, name: u.displayName }))
    } catch (error) {
        throw new Error(error)
    }
}

export const userAcceptTeams = async (user, teamIds: string[]) => {
    try {
        const teams = user.teams.map((team) => {
            if (teamIds.includes(team.id)) {
                const { invited, ...rest } = team
                return { ...rest }
            }
            return team
        })

        await updateDoc(doc(db, 'users', user.uid), { teams })

        const promises = teamIds.map(async (teamId) => {
            const teamRef = doc(db, 'teams', teamId)
            const teamDoc = await getDoc(teamRef)
            const teamData = teamDoc.data()

            const users = teamData.users.map((u) => {
                if (u.id === user.uid) {
                    const { invited, ...rest } = u
                    return { ...rest }
                }

                return u
            })

            await updateDoc(teamRef, { users })
        })

        await Promise.all(promises)
    } catch (error) {
        throw new Error(error)
    }
}

export const getUserSubscriptions = async (userId) => {
    try {
        const q = query(collection(db, 'users', userId, 'subscriptions'))
        const userSubscriptions = await getDocs(q)
        return userSubscriptions.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    } catch (error) {
        throw new Error(error)
    }
}
