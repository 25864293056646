import { TeamLibraryTalentData, UserData } from '../../../types'
import {
    addDoc,
    collection,
    collectionGroup,
    doc,
    getCountFromServer,
    getDoc,
    getDocs,
    query,
    serverTimestamp,
    setDoc,
    Timestamp,
    updateDoc,
    where,
    WhereFilterOp,
} from 'firebase/firestore'
import { db } from '../../../firebase_config/firebase_config'
import { AuditionFormValues, TalentData, TalentMedia } from '../../../components/Admin/Talent/AuditionForm/types'
import { UploadFile } from 'antd'
import { isEqual, omit, pick, uniqWith } from 'lodash'
import { parseTalentLocation, parseTalentSmartphoneModel } from '../../../components/Admin/Talent/AuditionForm/data'
import { compressImage, getFilenameTimestamp } from '../utils'
import { uploadFileFromUrl, uploadMediaFile } from '../storage'

export const getTalent = async (talentId, queryFilter?: any) => {
    try {
        if (talentId) {
            const ref = doc(collection(db, `talent`), talentId)

            const talent = await getDoc(ref)

            if (!talent.exists()) {
                return null
            }

            return { ...talent.data(), id: talent.id } as TalentData
        } else {
            const q = query(collection(db, `talent`), queryFilter)

            const talentsQuery = await getDocs(q)

            return { ...talentsQuery.docs[0].data(), id: talentsQuery.docs[0].id } as TalentData
        }
    } catch (error) {
        console.log(error)
    }
}

export const findTalentId = async (email: string) => {
    try {
        const q = query(collection(db, `talent`), where('email', '==', email))

        const res = await getDocs(q)

        return res.docs[0]?.id
    } catch (error) {
        console.log(error)
    }
}

export const createTalent = async (
    talentData: Omit<TalentData, 'id'>
): Promise<{
    success: boolean
    talentData: TalentData
    status: 'userExists' | 'new'
}> => {
    try {
        const [field, op, value] =
            talentData.email !== ''
                ? ['email', '==', talentData.email]
                : ['phoneNumber', '==', talentData.info.phoneNumber]

        const userQuery = query(collection(db, 'users'), where(field, op as WhereFilterOp, value))

        const userSnapshot = await getDocs(userQuery)

        let formattedTalentData: TalentData

        if (!userSnapshot.empty) {
            const userDoc = userSnapshot.docs[0]

            const talentRef = doc(db, 'talent', userDoc.id)

            formattedTalentData = {
                ...talentData,
                id: userDoc.id,
                userId: userDoc.id,
            }

            await setDoc(talentRef, {
                ...formattedTalentData,
                createdAt: Timestamp.fromDate(new Date(talentData.createdAt)),
            })

            await updateDoc(userDoc.ref, { talentProfileId: userDoc.id })
        } else {
            const userRef = doc(collection(db, 'users'))

            const userData: UserData = {
                email: talentData.email,
                displayName: talentData.name,
                uid: userRef.id,
                phoneNumber: talentData.info.phoneNumber,
                talentProfileId: userRef.id,
                emailVerified: true,
                noPassword: true,
                role: 'talent',
                createdAt: new Date(),
                teams: [],
            }

            await setDoc(userRef, {
                ...userData,
                createdAt: Timestamp.fromDate(userData.createdAt),
            })

            const talentRef = doc(collection(db, 'talent'), userRef.id)

            formattedTalentData = {
                ...talentData,
                id: talentRef.id,
                userId: talentRef.id,
            }

            await setDoc(talentRef, {
                ...formattedTalentData,
                createdAt: Timestamp.fromDate(new Date(talentData.createdAt)),
            })
        }

        return {
            success: true,
            talentData: formattedTalentData,
            status: !userSnapshot.empty ? 'userExists' : 'new',
        }
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const updateTalent = async (talentData: Partial<TalentData> & {
    id: string
}) => {
    const ref = doc(collection(db, `talent`), talentData.id)

    try {
        await updateDoc(ref, talentData)
    } catch (error) {
        console.log(error)
    }
}

export const updateTeamTalent = async (talent, talentId, team) => {
    const ref = doc(collection(db, `teams`, team, `talentLibrary`), talentId)
    try {
        await updateDoc(ref, talent)
    } catch (error) {
        console.log(error)
    }
}

export const tagTeamLibraryTalent = async (teamId, talentId, tags: string[]) => {
    const ref = doc(collection(db, `teams`, teamId, `talentLibrary`), talentId)
    try {
        await updateDoc(ref, {
            tags: tags,
        })
    } catch (error) {
        console.log(error)
    }
}

export const addBrandTalent = async (brandId, talent) => {
    const ref = doc(db, `brands`, brandId, `talentSuggestions`, talent.id)
    if ('exists' in talent) {
        //@ts-ignore
        delete talent.exists
    }
    if ('ref' in talent) {
        //@ts-ignore
        delete talent.ref
    }
    try {
        let status = 'pending'
        const snap = await getDoc(ref)
        if (snap.exists()) {
            const doc = snap.data()
            status = doc?.status || 'pending'
        }
        await setDoc(ref, { ...talent, brand: brandId, appliedAt: new Date(), status })
    } catch (error) {
        console.log(error)
    }
}

export const getTalentByEmail = async (email) => {
    const q = query(collection(db, `talent`), where('email', '==', email))
    try {
        const res = await getDocs(q)
        if (res.docs.length === 0) {
            return null
        } else {
            return res.docs[0].data()
        }
    } catch (error) {
        console.log(error)
    }
}

export const getBrandTalent = async (brandId) => {
    const ref = collection(db, `brands`, brandId, `talentSuggestions`)
    try {
        const res = await getDocs(ref)
        return res.docs.map((d) => ({ ...d.data(), id: d.id }))
    } catch (error) {
        console.log(error)
    }
}

export const newTalentProfile = async (talent) => {
    const ref = collection(db, `talent`)
    try {
        const docRef = await addDoc(ref, talent)

        updateDoc(docRef, {
            id: docRef.id,
        })

        return docRef.id
    } catch (error) {
        console.log(error)
    }
}

export const sendTalentApplication = async (teamId, talentData) => {
    const libData: TeamLibraryTalentData = {
        id: talentData.id,
        appliedAt: new Date(),
        status: 'pending',
        comments: [],
        tags: [],
        previouslyUsed: false,
        profile: talentData,
    }

    const ref = collection(db, `teams`, teamId, `talentLibrary`)
    try {
        await setDoc(doc(ref, talentData.id), libData)
    } catch (error) {
        console.log(error)
    }
}

export const getTalentBrandEntries = async (talentId) => {
    const ref = query(collectionGroup(db, `talentSuggestions`), where(`id`, `==`, talentId))
    try {
        const res = await getDocs(ref)
        return res.docs.map((d) => ({ ...d.data() }))
    } catch (error) {
        console.log(error)
    }
}

export const updateBrandTalent = async (brandId, talentId, updatedFields) => {
    const ref = doc(db, `brands`, brandId, `talentSuggestions`, talentId)
    try {
        await updateDoc(ref, updatedFields)
    } catch (error) {
        console.log(error)
    }
}

export const getBrandTalentById = async (brandId, talentId) => {
    const ref = doc(db, `brands`, brandId, `talentSuggestions`, talentId)
    try {
        const res = await getDoc(ref)
        return { ...res.data(), id: res.id }
    } catch (error) {
        console.log(error)
    }
}

export const changeStatusTeamLibraryTalent = async (teamId, talentId, status) => {
    const ref = doc(collection(db, `teams`, teamId, `talentLibrary`), talentId)
    try {
        await updateDoc(ref, {
            status: status,
        })
    } catch (error) {
        console.log(error)
    }
}

export const getTeamTalentLibrary = async (teamId: string) => {
    const ref = collection(db, 'teams', teamId, 'talentLibrary')

    return (await getDocs(ref)).docs.map((snap) => snap.data()) as TeamLibraryTalentData[]
}

export const getTeamTalentLibrarySize = async (teamId: string) => {
    const ref = collection(db, 'teams', teamId, 'talentLibrary')

    return (await getCountFromServer(ref)).data().count
}

export const checkTalentLibraryItem = async (teamId: string, email: string) => {
    const queryRes = await getDocs(query(
        collection(db, 'teams', teamId, 'talentLibrary'),
        where('profile.email', '==', email)
    ))

    return !!queryRes.docs.length
}

export const uploadTalentMedia = async ({
    file,
    type,
    talentEmail,
    talentId,
    teamId,
}: {
    file: File
    type: 'photo' | 'video'
    talentEmail?: string
    talentId?: string
    teamId?: string
}): Promise<TalentMedia> => {
    if ((!talentEmail && !teamId) || (teamId && !talentId)) {
        console.warn('uploadTalentMedia: wrong config')
        return
    }

    const compressedFile = type === 'photo' ? await compressImage(file) : file

    const filename = getFilenameTimestamp(file.name)

    const folderPath = teamId ? `/team/${teamId}/talentLibrary/${talentId}` : `/talent/${talentEmail}`

    const url = await uploadMediaFile({
        storagePath: `${folderPath}/${filename}`,
        file: compressedFile,
        fileType: type,
    })

    return {
        url,
        filename,
    }
}

export const getTalentDataFromValues = ({
    values,
}: {
    values: AuditionFormValues
}): Omit<TalentData, 'id' | 'createdAt'> => {
    return {
        name: `${values.info.firstName} ${values.info.lastName}`,
        email: values.info.email,
        headshot: values.photos.headshot as TalentMedia,
        info: {
            ...pick(values.info, [
                'portfolioLink',
                'phoneNumber',
                'preferredContactMethod',
                'dateOfBirth',
                'remoteWorkStatus',
            ]),
            location: parseTalentLocation(values?.info?.location),
            socialMediaProfiles: values.info.socialMediaProfiles.map((p) => p.url).filter(Boolean),
        },
        measurements: {
            ...values.measurements,
            height: `${values.measurements.height.feet}'${values.measurements.height.inch || 0}"`,
        },
        appearance: { ...values.appearance },
        equipment: {
            ...omit(values.equipmentAccess, ['smartphoneBrand', 'smartphoneModel']),
            smartphoneModel: parseTalentSmartphoneModel(
                values.equipmentAccess.smartphoneBrand,
                values.equipmentAccess.smartphoneModel
            ),
        },
        vocalInfo: {
            primaryLanguage: values.vocalInfo.primaryLanguage,
            additionalLanguages: values.vocalInfo.additionalLanguages,
            accents: values.vocalInfo.accents,
        },
        media: {
            talentPhotos: values.photos.extra as TalentMedia[],
            screenTest: values.screenTest as TalentMedia,
        },
        miscInfo: {
            ...values.miscInfo,
            rentalLocationPhotos: values.miscInfo.rentalLocationPhotos as TalentMedia[],
            industryPreferences: values.miscInfo.industryPreferences,
        },
    }
}

export const createUpdateTalentFromFormValues = async ({
    values,
    talentData,
}: {
    values: AuditionFormValues
    talentData?: TalentData
}): Promise<{
    talentData: TalentData
}> => {
    let uploadedHeadshot = values.photos.headshot
    let uploadedTalentPhotos = values.photos.extra
    let uploadedRentalPhotos = values.miscInfo.rentalLocationPhotos
    let uploadedScreenTest = values.screenTest

    try {
        if (values.photos.headshot && !values.photos.headshot?.url) {
            uploadedHeadshot = await uploadTalentMedia({
                file: (values.photos.headshot as UploadFile).originFileObj,
                type: 'photo',
                talentEmail: values.info.email,
            })
        }

        uploadedTalentPhotos = await Promise.all(
            values.photos.extra.map(async (photo) => {
                if (photo.url) return photo

                return await uploadTalentMedia({
                    file: (photo as UploadFile).originFileObj,
                    type: 'photo',
                    talentEmail: values.info.email,
                })
            })
        )

        uploadedRentalPhotos = await Promise.all(
            values.miscInfo.rentalLocationPhotos.map(async (photo) => {
                if (photo.url) return photo

                return await uploadTalentMedia({
                    file: (photo as UploadFile).originFileObj,
                    type: 'photo',
                    talentEmail: values.info.email,
                })
            })
        )

        if (values.screenTest && !values.screenTest?.url) {
            uploadedScreenTest = await uploadTalentMedia({
                file: (values.screenTest as UploadFile).originFileObj,
                type: 'video',
                talentEmail: values.info.email,
            })
        }
    } catch (err) {
        console.log(err)
        return
    }

    console.log('Media loaded')

    const talentDataFromValues = getTalentDataFromValues({
        values: {
            ...values,
            photos: {
                ...values.photos,
                headshot: uploadedHeadshot as TalentMedia,
                extra: uploadedTalentPhotos as TalentMedia[],
            },
            screenTest: uploadedScreenTest as TalentMedia,
            miscInfo: {
                ...values.miscInfo,
                rentalLocationPhotos: uploadedRentalPhotos as TalentMedia[],
            },
        },
    })

    const updatedTalentData = talentData
        ? ({
              ...talentData,
              ...talentDataFromValues,
          } as TalentData)
        : ({
              ...talentDataFromValues,
              createdAt: new Date(),
          } as Omit<TalentData, 'id'>)

    try {
        if (talentData) {
            await updateTalent(updatedTalentData as TalentData)

            return {
                talentData: updatedTalentData as TalentData,
            }
        } else {
            const result = await createTalent(updatedTalentData as Omit<TalentData, 'id'>)

            return {
                talentData: result.talentData,
            }
        }
    } catch (err) {
        return
    }
}

export const getTeamTalentDataFromFormValues = async ({
    values,
    teamId,
    talentData,
}: {
    values: AuditionFormValues
    teamId: string
    talentData: TalentData
}): Promise<TalentData> => {
    let uploadedHeadshot = values.photos.headshot
    let uploadedTalentPhotos = values.photos.extra
    let uploadedRentalPhotos = values.miscInfo.rentalLocationPhotos
    let uploadedScreenTest = values.screenTest

    try {
        if (values.photos.headshot) {
            if (values.photos.headshot.url) {
                const filename = (values.photos.headshot as TalentMedia).filename || 'headshot'

                const url = await uploadFileFromUrl(
                    values.photos.headshot.url,
                    `team/${teamId}/talentLibrary/${talentData.id}/${filename}`
                )

                uploadedHeadshot = {
                    url,
                    filename,
                }
            } else {
                uploadedHeadshot = await uploadTalentMedia({
                    file: (values.photos.headshot as UploadFile).originFileObj,
                    type: 'photo',
                    talentId: talentData.id,
                    teamId,
                })
            }
        }

        uploadedTalentPhotos = await Promise.all(
            values.photos.extra.map(async (photo, i) => {
                if (photo.url) {
                    const filename = (photo as TalentMedia).filename || `extra${i}`

                    const url = await uploadFileFromUrl(
                        photo.url,
                        `team/${teamId}/talentLibrary/${talentData.id}/${filename}`
                    )

                    return {
                        url,
                        filename,
                    }
                } else {
                    return await uploadTalentMedia({
                        file: (photo as UploadFile).originFileObj,
                        type: 'photo',
                        talentId: talentData.id,
                        teamId,
                    })
                }
            })
        )

        uploadedRentalPhotos = await Promise.all(
            values.miscInfo.rentalLocationPhotos.map(async (photo, i) => {
                if (photo.url) {
                    const filename = (photo as TalentMedia).filename || `rental${i}`

                    const url = await uploadFileFromUrl(
                        photo.url,
                        `team/${teamId}/talentLibrary/${talentData.id}/${filename}`
                    )

                    return {
                        url,
                        filename,
                    }
                } else {
                    return await uploadTalentMedia({
                        file: (photo as UploadFile).originFileObj,
                        type: 'photo',
                        talentId: talentData.id,
                        teamId,
                    })
                }
            })
        )

        if (values.screenTest) {
            if (values.screenTest.url) {
                const filename = (values.screenTest as TalentMedia).filename || 'screenTest'

                const url = await uploadFileFromUrl(
                    values.screenTest.url,
                    `team/${teamId}/talentLibrary/${talentData.id}/${filename}`
                )

                uploadedScreenTest = {
                    url,
                    filename,
                }
            } else {
                uploadedScreenTest = await uploadTalentMedia({
                    file: (values.screenTest as UploadFile).originFileObj,
                    type: 'video',
                    talentId: talentData.id,
                    teamId,
                })
            }
        }
    } catch (err) {
        console.log(err)
        return
    }

    console.log('Media loaded')

    return {
        ...talentData,
        ...getTalentDataFromValues({
            values: {
                ...values,
                photos: {
                    ...values.photos,
                    headshot: uploadedHeadshot as TalentMedia,
                    extra: uploadedTalentPhotos as TalentMedia[],
                },
                screenTest: uploadedScreenTest as TalentMedia,
                miscInfo: {
                    ...values.miscInfo,
                    rentalLocationPhotos: uploadedRentalPhotos as TalentMedia[],
                },
            },
        }),
        createdAt: talentData?.createdAt || new Date(),
        teams: uniqWith(
            [
                ...(talentData?.teams || []),
                {
                    id: teamId,
                },
            ],
            isEqual
        ),
    }
}

export const getTeamTalentDataFromData = async ({
    talentData,
    teamId,
}: {
    talentData: TalentData
    teamId: string
}): Promise<TalentData> => {
    try {
        const headshotUrl =
            talentData.headshot?.url &&
            (await uploadFileFromUrl(
                talentData.headshot.url,
                `team/${teamId}/talentLibrary/${talentData.id}/${talentData.headshot.filename || 'headshot'}`
            ))

        const talentPhotosUrls = await Promise.all(
            (talentData.media?.talentPhotos || []).map(async (item: any, i: number) => {
                return (
                    item?.url &&
                    (await uploadFileFromUrl(
                        item.url,
                        `team/${teamId}/talentLibrary/${talentData.id}/${item.filename || `talentPhoto${i}`}`
                    ))
                )
            })
        )

        const screenTestUrl =
            talentData.media?.screenTest?.url &&
            (await uploadFileFromUrl(
                talentData.media.screenTest.url,
                `team/${teamId}/talentLibrary/${talentData.id}/${talentData.media.screenTest.filename || 'screenTest'}`
            ))

        const rentalPhotosUrls = await Promise.all(
            (talentData.miscInfo?.rentalLocationPhotos || []).map(async (item: any, i: number) => {
                return (
                    item?.url &&
                    (await uploadFileFromUrl(
                        item.url,
                        `team/${teamId}/talentLibrary/${talentData.id}/${item.filename || `rentalPhoto${i}`}`
                    ))
                )
            })
        )

        return {
            ...talentData,
            headshot: talentData.headshot && {
                ...talentData.headshot,
                url: headshotUrl,
            },
            media: {
                ...talentData.media,
                talentPhotos: talentData.media?.talentPhotos?.map((item: any, i: number) => ({
                    ...item,
                    url: talentPhotosUrls[i],
                })),
                screenTest: talentData.media?.screenTest && {
                    ...talentData.media.screenTest,
                    url: screenTestUrl,
                },
            },
            miscInfo: {
                ...talentData.miscInfo,
                rentalLocationPhotos: talentData.miscInfo?.rentalLocationPhotos?.map((item: any, i: number) => ({
                    ...item,
                    url: rentalPhotosUrls[i],
                })),
            },
            teams: uniqWith(
                [
                    ...(talentData?.teams || []),
                    {
                        id: teamId,
                    },
                ],
                isEqual
            ),
        }
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const createUpdateTeamTalent = async ({
    talentData,
    teamId,
    status,
    formValues,
}: {
    talentData: TalentData
    teamId: string
    status: 'pending' | 'approved'
    formValues?: AuditionFormValues
}) => {
    const talentRef = doc(db, 'talent', talentData.id)

    const teamRef = doc(db, 'teams', teamId)
    const teamData = (await getDoc(teamRef)).data()
    const teamTalentLibRef = doc(teamRef, 'talentLibrary', talentData.id)

    try {
        const teamTalentData = formValues
            ? await getTeamTalentDataFromFormValues({
                  values: formValues,
                  talentData: talentData,
                  teamId,
              })
            : await getTeamTalentDataFromData({
                  talentData: talentData,
                  teamId,
              })

        const application = {
            id: talentData.id,
            appliedAt: serverTimestamp(),
            status,
            comments: [],
            tags: [],
            previouslyUsed: false,
            profile: teamTalentData,
        }

        await setDoc(teamTalentLibRef, application)

        await updateDoc(teamRef, {
            invitedTalents: teamData.invitedTalents?.filter((t) => t.email !== talentData.email),
        })

        await updateDoc(talentRef, {
            teams: [
                ...(talentData.teams || []),
                {
                    id: teamId,
                },
            ],
        })

        return {
            success: true,
        }
    } catch (error) {
        console.log(error)
        throw error
    }
}
