import { useCollection, useGetDoc } from '@tatsuokaniwa/swr-firestore'
import { FC, ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useBrandContext } from '../../../contexts/BrandContext'
import { useTeamContext } from '../../../contexts/TeamContext'
import useProductAdditionalStorage from '../../../hooks/useProductAdditionalStorage'
import useSubscriptionProducts from '../../../hooks/useSubscriptionProducts'
import { useAuth } from './AuthContext'
import { StripeSubscription, StripeSubscriptionSchedule, StripeItem, StripeMetadata } from '../../../types'

interface IBillingContext {
    subscriptions: StripeSubscription[]
    activeSubscription: StripeSubscription | null
    lastSubscription: StripeSubscription | null
    subscriptionStatus: string | null
    subscriptionPlan: string | null
    isCustomPlan: boolean
    price: {
        itemId: string | null
        priceId: string | null
        value: number
    }
    activeSubscriptionMetadata: StripeMetadata
    activeCreatives: number
    activeSubscriptionProduct: any
    additionalStorageGb: number
    storageLimitBytes: number
    storageLimitGb: number
    schedule: {
        product: string
        activeCreatives: number
        newAddonStorage: number
        id: string
        productId: string
        priceId: string
        storage: number
    } | null
    talentLimit: number
    brandBillingInfo: {
        isBrandSubscriptionActive: boolean
        activeCreatives: number
        storageLimitBytes: number
        storageLimitGb: number
        talentLimit: number
        periodStart: Date
    }
    subscriptionProducts: any[]
}

export enum PLAN {
    BASIC = 'basic',
    FULL = 'full',
    PRO = 'enterprise',
}

export const DEBUG_STORAGE_DIVIDER = 1
const GIGABYTE_MULTIPLIER = 1024 ** 3
const ADDON_STORAGE_MULTIPLIER = 100

const gigabytesToBytes = (gb: number) => gb * GIGABYTE_MULTIPLIER
const addonStorageToGb = (addon: number) => (addon * ADDON_STORAGE_MULTIPLIER) / DEBUG_STORAGE_DIVIDER

const isSubscriptionNickname = (nickname: string) => {
    if (!nickname) {
        return false
    }
    return [PLAN.BASIC, PLAN.FULL, PLAN.PRO].map((p) => nickname.includes(p)).includes(true)
}

const findSubcriptionPriceItem = (items: StripeItem[]) => {
    return items.find((it) => isSubscriptionNickname(it.price?.nickname))
}

const calculateAdditionalStorage = (items: StripeItem[]) => {
    const asItem = items.find((it) => it.price.product.metadata?.nickname === 'additionalStorage')
    return asItem ? Number(asItem.quantity) : 0
}

const calculateStorageLimit = (price: any, product: any) => {
    if (!product || !product?.metadata?.storage) {
        return 0
    }

    if (price && price.metadata?.storage) {
        return parseInt(price.metadata.storage)
    }

    return parseInt(product.metadata.storage) / DEBUG_STORAGE_DIVIDER
}

const calculateTalentLimit = (price: any, product: any) => {
    if (!product || !product?.metadata?.actors) {
        return 0
    }

    if (price && price.metadata?.actors) {
        if (price.metadata.actors === 'unlimited') {
            return Infinity
        }
        return parseInt(price.metadata.actors)
    }

    if (product.metadata.actors === 'unlimited') {
        return Infinity
    }
    return parseInt(product.metadata.actors)
}

export const BillingContext = createContext<IBillingContext>(null!)

export const useBilling = () => useContext(BillingContext)

const BillingProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const { userDB } = useAuth()
    const { additionalStoragePrice } = useProductAdditionalStorage()
    const { subscriptionProducts } = useSubscriptionProducts()
    const { currentTeamOwnerId, isViscapTeam } = useTeamContext()
    const { ownerTeamId: brandOwnerTeamId, isViscapBrand, brandOwnerId } = useBrandContext()

    const [activeSubscription, setActiveSubscription] = useState<any>(null)
    const [brandOwnerActiveSubscription, setBrandOwnerActiveSubscription] = useState<any>(null)
    const [lastSubscription, setLastSubscription] = useState<any>(null)

    const { data: subscriptions = null } = useCollection<StripeSubscription>(
        userDB && currentTeamOwnerId
            ? {
                  path: `users/${currentTeamOwnerId}/subscriptions`,
                  parseDates: ['created', 'current_period_end', 'current_period_start', 'canceled_at'],
              }
            : null
    )

    const { data: brandOwnerSubscriptions = null } = useCollection<StripeSubscription>(
        userDB && brandOwnerId
            ? {
                  path: `users/${brandOwnerId}/subscriptions`,
                  parseDates: ['created', 'current_period_end', 'current_period_start', 'canceled_at'],
              }
            : null
    )

    const { data: schedules = null } = useCollection<StripeSubscriptionSchedule>(
        userDB && currentTeamOwnerId && activeSubscription
            ? {
                  path: `users/${currentTeamOwnerId}/subscriptions/${activeSubscription.id}/schedules`,
                  parseDates: ['phases.0.start_date', 'phases.0.end_date', 'phases.1.start_date'],
              }
            : null
    )

    const getActiveSub = (subs: StripeSubscription[]) => {
        const activeSubs = subs.filter((sub) => ['active', 'trialing', 'past_due'].includes(sub.status))
        if (activeSubs.length === 0) {
            return null
        }

        if (activeSubs.length === 1) {
            return activeSubs[0]
        }

        let activeSub = activeSubs.find((sub) => sub.status === 'active')
        if (activeSub) {
            return activeSub
        }

        activeSub = activeSubs.find((sub) => sub.status === 'past_due')
        if (activeSub) {
            return activeSub
        }

        return activeSubs.find((sub) => sub.status === 'trialing') || null
    }

    useEffect(() => {
        if (subscriptions) {
            const activeSub = getActiveSub(subscriptions)
            setActiveSubscription(activeSub)

            if (!activeSub) {
                setLastSubscription(subscriptions[subscriptions.length - 1] || null)
            }
        }
    }, [subscriptions])

    useEffect(() => {
        if (brandOwnerSubscriptions) {
            const activeSub = getActiveSub(brandOwnerSubscriptions)
            setBrandOwnerActiveSubscription(activeSub || { status: 'inactive' })
        }
    }, [brandOwnerSubscriptions])

    const subscriptionStatus = useMemo(() => {
        if (activeSubscription) {
            return activeSubscription.status
        }

        if (lastSubscription) {
            return lastSubscription.status
        }

        return null
    }, [activeSubscription, lastSubscription])

    const brandOwnerSubscriptionStatus = useMemo(() => {
        if (brandOwnerActiveSubscription) {
            return brandOwnerActiveSubscription.status
        }

        return null
    }, [brandOwnerActiveSubscription])

    const additionalStorageGb = useMemo(() => {
        const sub = activeSubscription //?? lastSubscription
        if (!sub || !sub.prices) {
            return 0
        }

        if (sub.prices.length === 1) {
            return 0
        }

        return addonStorageToGb(calculateAdditionalStorage(sub.items))
    }, [activeSubscription])

    const price = useMemo(() => {
        const sub = activeSubscription //?? lastSubscription
        if (!sub || !sub.prices) {
            return null
        }

        if (sub.prices.length === 0) {
            return null
        }

        const asItem = findSubcriptionPriceItem(sub.items)
        if (!asItem) {
            return null
        }

        return asItem.price
    }, [activeSubscription])

    const priceItemId = useMemo(() => {
        const sub = activeSubscription //?? lastSubscription
        if (!sub || !sub.prices) {
            return ''
        }

        if (sub.prices.length === 0) {
            return ''
        }

        const asItem = findSubcriptionPriceItem(sub.items)
        if (!asItem) {
            return ''
        }

        return asItem.id
    }, [activeSubscription])

    const brandOwnerPrice = useMemo(() => {
        const sub = brandOwnerActiveSubscription
        if (!sub || !sub.prices) {
            return null
        }

        if (sub.prices.length === 0) {
            return null
        }

        const asItem = findSubcriptionPriceItem(sub.items)
        if (!asItem) {
            return null
        }

        return asItem.price
    }, [brandOwnerActiveSubscription])

    const product = useMemo(() => {
        return price?.product || null
    }, [price])

    const brandOwnerProduct = useMemo(() => {
        return brandOwnerPrice?.product || null
    }, [brandOwnerPrice])

    const activeSubscriptionMetadata = useMemo(() => {
        if (price && price.metadata) {
            return price.metadata
        }

        if (product && product.metadata) {
            return product.metadata
        }

        return null
    }, [price, product])

    const activeCreatives = useMemo(() => {
        if (!price) {
            return 0
        }

        if (isSubscriptionNickname(price.nickname)) {
            const [_, creatives] = price.nickname.split('_')
            return parseInt(creatives)
        }

        return 0
    }, [price])

    const isCustomPlan = useMemo(() => {
        if (!price) {
            return false
        }

        return price.nickname.split('_').length === 3
    }, [price])

    const priceValueUSD = useMemo(() => {
        if (!price) {
            return 0
        }

        return Math.round(Number(price.unit_amount) / 100)
    }, [price])

    const brandOwnerActiveCreatives = useMemo(() => {
        if (!brandOwnerPrice) {
            return 0
        }

        if (isSubscriptionNickname(brandOwnerPrice.nickname)) {
            const [_, creatives] = brandOwnerPrice.nickname.split('_')
            return parseInt(creatives)
        }

        return 0
    }, [brandOwnerPrice])

    const storageGb: number = useMemo(() => {
        // console.log('storageGb product', product)

        if (isViscapTeam) {
            return Infinity
        }

        return calculateStorageLimit(price, product)
    }, [product, price, isViscapTeam])

    const brandOwnerStorageGb: number = useMemo(() => {
        // console.log('storageGb brandOwnerProduct', brandOwnerProduct)

        if (isViscapBrand) {
            return Infinity
        }

        return calculateStorageLimit(brandOwnerPrice, brandOwnerProduct)
    }, [brandOwnerProduct, brandOwnerPrice, isViscapBrand])

    const talentLimit: number = useMemo(() => {
        // console.log('talentLimit product', product)

        if (isViscapTeam) {
            return Infinity
        }

        return calculateTalentLimit(price, product)
    }, [product, price, isViscapTeam])

    const brandOwnerTalentLimit: number = useMemo(() => {
        // console.log('talentLimit product', brandOwnerProduct)

        if (isViscapBrand) {
            return Infinity
        }

        return calculateTalentLimit(brandOwnerPrice, brandOwnerProduct)
    }, [brandOwnerProduct, brandOwnerPrice, isViscapBrand])

    const isBrandSubscriptionActive = useMemo(() => {
        if (isViscapBrand) {
            return true
        }

        if (brandOwnerSubscriptionStatus === null) {
            return null
        }

        if (!brandOwnerActiveSubscription) {
            return false
        }

        return ['active', 'trialing', 'past_due'].includes(brandOwnerSubscriptionStatus)
    }, [brandOwnerActiveSubscription, brandOwnerSubscriptionStatus, isViscapBrand])

    const additionalStorageBrandGb = useMemo(() => {
        const sub = brandOwnerActiveSubscription
        if (!sub || !sub.prices) {
            return 0
        }

        if (sub.prices.length === 1) {
            return 0
        }

        return addonStorageToGb(calculateAdditionalStorage(sub.items))
    }, [brandOwnerActiveSubscription])

    const schedule = useMemo(() => {
        if (!schedules || schedules.length === 0 || !additionalStoragePrice || subscriptionProducts.length === 0) {
            return null
        }

        const now = new Date()
        const activeSchedule = schedules.find((sch) => sch.phases[1].start_date > now)
        if (!activeSchedule) {
            return null
        }

        const newItems = activeSchedule.phases[1].items
        const newSubPrice = newItems[0].price
        let newAddonStorage = 0
        if (newItems.length === 2 && newItems[1].price === additionalStoragePrice.id) {
            newAddonStorage = Number(newItems[1].quantity)
        }

        let prodName = ''
        let productId = ''
        let activeCreatives = 0

        for (const prod of subscriptionProducts) {
            for (const [key, value] of Object.entries(prod.priceIds)) {
                if (value === newSubPrice) {
                    prodName = prod.name
                    productId = prod.id
                    activeCreatives = Number(key.split('_')[1])
                    break
                }
            }

            if (prodName) {
                break
            }
        }

        return {
            id: activeSchedule.id,
            product: prodName,
            productId,
            priceId: newSubPrice,
            activeCreatives,
            newAddonStorage,
        }
    }, [schedules, additionalStoragePrice, subscriptionProducts])

    const { data: scheduledNewPrice = null } = useGetDoc<any>(
        schedule ? { path: `stripe-products/${schedule.productId}/prices/${schedule.priceId}` } : null
    )

    const scheduledNewPriceMetadata = useMemo(() => {
        if (!scheduledNewPrice) {
            return null
        }

        if (Object.keys(scheduledNewPrice.metadata).length === 0) {
            if (!subscriptionProducts) {
                return null
            }

            const product = subscriptionProducts.find((p) => p.id === schedule.productId)
            return product?.metadata
        }

        return scheduledNewPrice.metadata
    }, [scheduledNewPrice, subscriptionProducts, schedule])

    const storageLimitGb = storageGb + additionalStorageGb
    const brandStorageLimitGb = brandOwnerStorageGb + additionalStorageBrandGb

    const storageLimitBytes = gigabytesToBytes(storageLimitGb)
    const brandStorageLimitBytes = gigabytesToBytes(brandStorageLimitGb)

    const value = {
        subscriptions,
        activeSubscription,
        lastSubscription,
        subscriptionStatus,
        subscriptionPlan: product?.name || '',
        isCustomPlan,
        price: {
            itemId: priceItemId,
            priceId: price?.id || null,
            value: priceValueUSD,
        },
        activeSubscriptionMetadata,
        activeCreatives,
        activeSubscriptionProduct: product,
        additionalStorageGb,
        storageLimitBytes,
        storageLimitGb,
        schedule: schedule
            ? {
                  ...schedule,
                  storage: scheduledNewPriceMetadata?.storage || 0,
              }
            : null,
        talentLimit,
        brandBillingInfo: {
            isBrandSubscriptionActive,
            activeCreatives: brandOwnerActiveCreatives,
            storageLimitBytes: brandStorageLimitBytes,
            storageLimitGb: brandStorageLimitGb,
            talentLimit: brandOwnerTalentLimit,
            periodStart: brandOwnerActiveSubscription?.current_period_start,
        },
        subscriptionProducts,
    }

    // console.log('BillingContext', value)

    return <BillingContext.Provider value={value}>{children}</BillingContext.Provider>
}

export default BillingProvider
