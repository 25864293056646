import { Loading3QuartersOutlined } from '@ant-design/icons'
import { Spin, theme } from 'antd'
import { useTheme } from 'antd-style'
import { FC } from 'react'

type SpinnerProps = {
    size?: 'small' | 'middle' | 'large'
    className?: string
}

const Spinner: FC<SpinnerProps> = ({ className = '', size = 'middle' }) => {
    const theme = useTheme()

    const sizeMap = new Map([
        ['small', 14],
        ['middle', 20],
        ['large', 32],
    ])

    const fontSize = sizeMap.get(size)

    return (
        <Spin
            className={className}
            indicator={<Loading3QuartersOutlined style={{ fontSize, color: theme.colorText }} spin />}
        />
    )
}

export default Spinner
