import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useAuth } from '../features/Auth/contexts/AuthContext'
import { Team, UserData } from '../types'
import { getTeamsByIds, saveTeam } from '../api/firestore/teams'
import { getUsersByTeam } from '../api/firestore/users'
import { getTeamTemplates, updateTemplate } from '../api/firestore/template'
import { useDoc, useGetDoc } from '@tatsuokaniwa/swr-firestore'

interface TeamContextData {
    userTeams: Team[]
    currentTeam: Team | null
    reloadTeam: (id: string, isCurrent?: boolean) => Promise<void>
    currentTeamUsers: any[]
    currentTeamUsersLoading: boolean
    changeTeam: (newTeam: Team) => void
    handleUpdateCurrentTeamUsers: (users: any[]) => void
    handleUpdateTeam: (team: Team) => void
    templates: any[]
    handleUpdateTemplates: (templates: any[]) => void
    handleUpdateTemplate: (template: any) => void
    currentTeamOwnerId: string
    isOwner: boolean
    isViscapTeam: boolean
    isTalentView: boolean
    isTeamOwnerDeleted: boolean
    teamOwner: UserData | null
}

export const TeamContext = createContext<TeamContextData>(null!)

export const useTeamContext = () => useContext(TeamContext)

const TeamContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const { userDB } = useAuth()

    const [userTeams, setUserTeams] = useState<Team[]>([])
    const [currentTeamId, setCurrentTeamId] = useState<string>('')
    const [currentTeamUsers, setCurrentTeamUsers] = useState([])
    const [currentTeamUsersLoading, setCurrentTeamUsersLoading] = useState<boolean>(false)
    const [currentTeamOwnerId, setCurrentTeamOwnerId] = useState<string>('')
    const [isOwner, setIsOwner] = useState<boolean>(false)

    const [templates, setTemplates] = useState([])

    const { data: currentTeam = null } = useDoc<Team>(
        userDB && currentTeamId
            ? {
                  path: `teams/${currentTeamId}`,
              }
            : null
    )

    const { data: teamOwner = null } = useGetDoc<UserData>(
        currentTeamOwnerId
            ? {
                  path: `users/${currentTeamOwnerId}`,
                  parseDates: ['removeOn'],
              }
            : null
    )

    const isTalentView = useMemo(
        () => !!userDB && !!currentTeam && userDB?.role === 'talent' && currentTeam?.email === userDB?.email,
        [currentTeam, userDB]
    )

    const reloadTeam = useCallback(async (id: string, isCurrent?: boolean) => {}, [])

    const isViscapTeam = useMemo(() => {
        if (!currentTeam) return false

        return currentTeam.email.endsWith('@viscapmedia.com')
    }, [currentTeam])

    useEffect(() => {
        if (currentTeam && userDB) {
            const owner = currentTeam.users.find((u) => u.teamRole === 'owner')
            if (!owner) {
                console.error(`No owner found for team ${currentTeam.id}`)
            }
            setCurrentTeamOwnerId(owner.id)
            setIsOwner(owner.id === userDB.uid)
        }
    }, [currentTeam, userDB])

    useEffect(() => {
        if (currentTeam) {
            setUserTeams((prev) =>
                prev.map((team) => {
                    if (team.id === currentTeam.id) {
                        return currentTeam
                    }
                    return team
                })
            )
        }
    }, [currentTeam])

    const changeTeam = useCallback((newTeam) => {
        setCurrentTeamId(newTeam.id)
        localStorage.setItem('team', JSON.stringify(newTeam))
    }, [])

    const handleUpdateCurrentTeamUsers = useCallback((users) => setCurrentTeamUsers(users), [])

    const handleUpdateTeam = useCallback(async (updatedTeam) => {
        try {
            const { ref, exists, ...data } = updatedTeam
            await saveTeam(data)
        } catch (err) {
            console.log(err)
        }
    }, [])

    const handleUpdateTemplates = useCallback((templates) => setTemplates(templates), [])

    const handleUpdateTemplate = useCallback(
        async (template) => {
            try {
                await updateTemplate(currentTeam.id, template)
                const newTemplates = [...templates.map((t) => (t.id === template.id ? template : t))]
                setTemplates(newTemplates)
            } catch (err) {
                console.log(err)
            }
        },
        [currentTeam, templates]
    )

    useEffect(() => {
        const fetchTeams = async () => {
            const teams = await getTeamsByIds(userDB.teams.filter((t) => t?.invited !== true))
            setUserTeams(teams)
        }

        if (userDB?.teams) {
            fetchTeams()
        }
    }, [userDB])

    useEffect(() => {
        if (userTeams?.length > 0) {
            if (localStorage.getItem('team')) {
                setCurrentTeamId(
                    userTeams.find((b) => b.id === JSON.parse(localStorage.getItem('team')).id)?.id || userTeams[0].id
                )
            } else {
                setCurrentTeamId(userTeams[0].id)
                localStorage.setItem('team', JSON.stringify(userTeams[0]))
            }
        }
    }, [userTeams])

    useEffect(() => {
        const fetchTeamUsers = async () => {
            try {
                const users = await getUsersByTeam(currentTeam.id)
                setCurrentTeamUsers(users.filter((u) => !!u))
            } catch (err) {
                console.log(err)
            }
        }

        if (currentTeam?.id) {
            setCurrentTeamUsersLoading(true)
            fetchTeamUsers().finally(() => setCurrentTeamUsersLoading(false))
        }
    }, [currentTeam])

    useEffect(() => {
        const fetchTemplates = async () => {
            const res = await getTeamTemplates(currentTeam.id)
            setTemplates(res)
        }
        if (currentTeam?.id) {
            fetchTemplates()
        }
    }, [currentTeam])

    const value = {
        userTeams,
        currentTeam,
        reloadTeam,
        currentTeamUsers,
        currentTeamUsersLoading,
        changeTeam,
        handleUpdateCurrentTeamUsers,
        handleUpdateTeam,
        templates,
        handleUpdateTemplates,
        handleUpdateTemplate,
        currentTeamOwnerId,
        isOwner,
        isViscapTeam,
        isTalentView,
        isTeamOwnerDeleted: !!teamOwner?.removeOn,
        teamOwner,
    }

    return <TeamContext.Provider value={value}>{children}</TeamContext.Provider>
}

export default TeamContextProvider
