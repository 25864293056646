import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from 'firebase/firestore'
import { db } from '../../../firebase_config/firebase_config'

export const getTeamTemplates = async (teamId) => {
    let res = []

    const q = collection(db, 'teams', teamId, 'creativeViewsTemplates')

    try {
        const snapshot = await getDocs(q)
        snapshot.forEach((d) => res.push({ id: d.id, ...d.data() }))
        return res
    } catch (error) {
        console.log(error.message)
    }
}

export const addTeamTemplate = async (teamId, template) => {
    try {
        const res = await addDoc(collection(db, `teams`, teamId, 'creativeViewsTemplates'), template)
        return res.id
    } catch (error) {
        console.log(error)
    }
}

export const deleteTemplate = async (teamId, templateId) => {
    const docRef = doc(collection(db, `teams`, teamId, 'creativeViewsTemplates'), templateId)
    try {
        await deleteDoc(docRef)
    } catch (error) {
        console.log(error)
    }
}

export const updateTemplate = async (teamId, template) => {
    const docRef = doc(db, 'teams', teamId, 'creativeViewsTemplates', template.id)
    try {
        const res = await updateDoc(docRef, template)
        return res
    } catch (error) {
        console.log(error)
    }
}
