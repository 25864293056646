import { defineAbility } from "@casl/ability";
import { UserData } from "../types";

export const siteAdminAbility = defineAbility((can, cannot) => {
    can('manage', 'all');
});

export const newUserAbility = (userData?: UserData) => defineAbility((can, cannot) => {
    can('read', 'all')
    can('create', 'brand')
    can('update', 'brand', { teams: { $in: userData?.teams?.map(t => t?.id) } })
    can('create', 'team')
    can('update', 'team', { id: { $in: userData?.teams?.filter(t => t?.teamRole === 'owner')?.map(t => t?.id) }})
})