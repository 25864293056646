import { collection, doc, getDoc, setDoc } from "firebase/firestore"
import { db } from "../../../firebase_config/firebase_config"

export const setBrandLocationsFirestore = async (brandId: string, locations: string[]) => {
    const ref = doc(db, 'brandLocations', brandId)
    try {
        await setDoc(ref, { locations })
    } catch (e) {
        console.log(e)
    }
}

export const getBrandLocations = async (brandId: string) => {
    try {
        const ref = doc(db, 'brandLocations', brandId)
        const docSnap = await getDoc(ref)
        if (docSnap.exists()) {
            return docSnap.data().locations
        } else {
            return null
        }
    } catch (e) {
        console.log(e)
    }
}